import { Injectable, LOCALE_ID, inject } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isPageWithoutCountries, Page } from '../types/page';
import { Observable, combineLatest, of } from 'rxjs';
import { ContentRepositoryService } from './content-repository.service';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { LocaleService } from './locale.service';
import { SettingsService } from './settings.service';
import { GLOBAL_REGION } from './settings-constants';
import { LoggerService } from './logger.service';
import { PageService } from './page.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicPageResolver  {
  private contentRepositoryService = inject(ContentRepositoryService);
  private locale = inject(LocaleService);
  private settings = inject(SettingsService);
  private location = inject(Location);
  private logger = inject(LoggerService);
  private pageService = inject(PageService);
  private localeId = inject(LOCALE_ID);

  public resolve(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<Page> {
    this.logger.renderer.debug('Starting page resolver with url', { routerStateUrl: routerState.url });
    const url = this.getURL(routerState.url);
    const path = url.pathname;
    this.logger.renderer.debug('Escaped url path to', { escapedPath: path });
    this.pageService.setLoading();

    return this.contentRepositoryService.fetch<Page>(path).pipe(
      catchError((error) => {
        if (!(error instanceof HttpErrorResponse)) {
          return this.contentRepositoryService.handleError(error, this.localeId, undefined, true);
        }

        if (error.status !== 404) {
          return this.contentRepositoryService.handleHttpError(error, this.localeId, undefined, true);
        }

        return combineLatest([
          this.settings.countrySettingUrls,
          this.settings.fallbackCountry,
          this.settings.fallbackLanguage,
        ]).pipe(
          first(),
          switchMap(([countrySettingUrls, fallbackRegion, fallbackLanguage]) => {
            const globalCountryLanguages = this.locale.convertCountryLanguageUrlsToCountryLanguages(countrySettingUrls);
            if (this.urlStartsWithCountryLanguage(path, globalCountryLanguages)) {
              return this.contentRepositoryService.handleHttpError(error, this.localeId, undefined, true);
            }

            let fallbackUrl = `/${fallbackLanguage}`;
            if (fallbackRegion && fallbackRegion !== GLOBAL_REGION) {
              fallbackUrl += `/${fallbackRegion.toLowerCase()}`;
            }
            fallbackUrl += path;

            this.logger.renderer.debug('Trying to fetch fallback url', { fallbackUrl: fallbackUrl });

            return this.contentRepositoryService.fetch<Page>(fallbackUrl).pipe(
              catchError((error) => this.contentRepositoryService.handleError(error, fallbackLanguage, fallbackRegion === GLOBAL_REGION ? undefined : fallbackRegion, true)),
              switchMap((page) => {
                let optimalRegion: string | undefined;
                let optimalLanguage: string;
                let optimalUrl: string;
                if (isPageWithoutCountries(page)) {
                  optimalLanguage = this.locale.getOptimalLanguage(Object.keys(page.languages), fallbackLanguage);
                  optimalUrl = page.languages[optimalLanguage];
                } else {
                  const pageCountryLanguages = this.locale.convertCountryLanguageUrlsToCountryLanguages(page.countryLanguageCombinations);
                  this.logger.renderer.debug('Page available country & languages', { countryLanguages: pageCountryLanguages });
                  const optimalLocale = this.locale.getOptimalLocale(pageCountryLanguages, fallbackLanguage, fallbackRegion);
                  optimalLanguage = optimalLocale.language;
                  optimalRegion = optimalLocale.region;
                  optimalUrl = page.countryLanguageCombinations[optimalLocale.region][optimalLocale.language];
                }

                const originalQueryParams = url.search.substring(1);
                const originalFragment = url.hash;

                if (optimalRegion === fallbackRegion && optimalLanguage === fallbackLanguage) {
                  this.logger.renderer.debug('Optimal region & language = fallback', { fallbackUrl: fallbackUrl });
                  this.location.replaceState(fallbackUrl + originalFragment, originalQueryParams);
                  return of(page);
                }

                const escapedOptimalUrl = this.getURL(optimalUrl).pathname;

                this.logger.renderer.debug('Trying to fetch optimal url', { optimalUrl: optimalUrl, escapedOptimalUrl: escapedOptimalUrl });

                this.location.replaceState(escapedOptimalUrl + originalFragment, originalQueryParams);
                return this.contentRepositoryService.fetch<Page>(escapedOptimalUrl).pipe(
                  catchError((error) => { return this.contentRepositoryService.handleError(error, optimalLanguage, optimalRegion, true) }),
                );
              }),
            );
          }),
        );

      }),
      tap(page => page.path = path),
    );

  }

  private getURL(url: string): URL {
    return (new URL(url, 'https://fakebase'));
  }

  private urlStartsWithCountryLanguage(url: string, countryLanguages: {[key: string]: string[]}): boolean {
    for (const [country, languages] of Object.entries(countryLanguages)) {
      if (country !== GLOBAL_REGION) {
        if (languages.some(language => url.startsWith(`/${language}/${country}`))) {
          return true;
        }
      } else {
        if (languages.some(language => url.startsWith(`/${language}`))) {
          return true;
        }
      }
    }
    return false;
  }

}
